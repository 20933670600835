import { BLOCKS } from "@contentful/rich-text-types";
import { Typography, Image } from "antd";

export const options = {
  renderMark: {},
  renderNode: {
    [BLOCKS.HEADING_1]: (text, children) => (
      <Typography.Title level={1}>{children}</Typography.Title>
    ),
    [BLOCKS.HEADING_2]: (text, children) => (
      <Typography.Title level={2}>{children}</Typography.Title>
    ),
    [BLOCKS.HEADING_3]: (text, children) => (
      <Typography.Title level={3}>{children}</Typography.Title>
    ),
    [BLOCKS.HEADING_4]: (text, children) => (
      <Typography.Title level={4}>{children}</Typography.Title>
    ),
    [BLOCKS.HEADING_5]: (text, children) => (
      <Typography.Title level={5}>{children}</Typography.Title>
    ),
    [BLOCKS.HEADING_6]: (text, children) => (
      <Typography.Title level={6}>{children}</Typography.Title>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <Image
          src={`https:${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.title}
          style={{ marginBottom: 15 }}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { id } = node.data.target.sys.contentType.sys;
      switch (id) {
        case "codeBlock":
          const { language, code } = node.data.target.fields;
          return (
            <pre className={`${language} CodeBlock`}>
              <code>{code}</code>
            </pre>
          );
        default:
          return null;
      }
    },
  },
};
