import React, { createContext, useState } from "react";

const UserContext = createContext();

const UserProvider = (props) => {
  const [sidebar, setSidebar] = useState({ isVisible: true });

  const value = {
    sidebar,
    setSidebar,
  };

  return <UserContext.Provider value={{ value }} {...props} />;
};

export { UserProvider, UserContext };
