import { Layout } from "antd";
import React from "react";
import header from "../../../images/background-header.png";

function Header() {
  return (
    <Layout
      style={{
        height: 400,
        backgroundImage: `url(${header})`,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontSize: "64px",
        fontWeight: 700,
        color: "#5d30ae",
        letterSpacing: ".3rem",
      }}
    >
      <span>Welcome</span>
    </Layout>
  );
}

export default Header;
