import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Alert,
  Typography,
  Divider,
} from "antd";
import LayoutContact from "./LayoutContact";
import {
  MailOutlined,
  FacebookOutlined,
  TwitterOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const style = {};

function FormContact() {
  const [form] = Form.useForm();
  const [setFormContact] = useState({
    name: null,
    email: null,
    subject: null,
    message: null,
  });
  const [success, setSuccess] = useState();
  const [grid, setGrid] = useState();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowSize.width < 768) {
      setGrid(24);
    } else {
      setGrid(12);
    }
  }, [windowSize.width]);

  const onFinish = (values) => {
    setFormContact({
      name: values.name,
      email: values.email,
      subject: values.subject,
      message: values.message,
    });
    setSuccess("Thanks! I am gonna reply your message soon!");
    form.resetFields();
  };

  return (
    <LayoutContact>
      {success ? (
        <Alert
          message={success}
          type="success"
          style={{ marginBottom: 20 }}
          showIcon
          closable
        />
      ) : null}
      <Typography.Title level={1} style={{ marginBottom: 20 }}>
        Contact me
      </Typography.Title>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={grid} style={{ marginBottom: 30 }}>
          <Col style={style}>
            <Col style={{ marginBottom: 10 }}>
              <Typography.Text>
                Feel free to contact me guys! Anything about the Article.
              </Typography.Text>
            </Col>
            <Col>
              <MailOutlined style={{ marginRight: 10, fontSize: 24 }} />
              <Typography.Text>teguhatmayudha@gmail.com</Typography.Text>
            </Col>
          </Col>
          <Col style={{ marginTop: 30 }}>
            <Divider orientation="left">
              <Typography.Title level={5} style={{ margin: 0 }}>
                and follow me on social media
              </Typography.Title>
            </Divider>
            <Col style={{ marginBottom: 10 }}>
              <Typography.Text>
                <FacebookOutlined
                  style={{ marginRight: 10, fontSize: 24, color: "#065da3" }}
                />{" "}
                <Link to="/">teguhatma</Link>
              </Typography.Text>
            </Col>
            <Col style={{ marginBottom: 10 }}>
              <Typography.Text>
                <TwitterOutlined
                  style={{ marginRight: 10, color: "#00bcd4", fontSize: 24 }}
                />{" "}
                <Link to="/">teguhatma</Link>
              </Typography.Text>
            </Col>
            <Col style={{ marginBottom: 10 }}>
              <Typography.Text>
                <GithubOutlined style={{ marginRight: 10, fontSize: 24 }} />{" "}
                <Link to="/">teguhatma</Link>
              </Typography.Text>
            </Col>
          </Col>
        </Col>
        <Col className="gutter-row" span={grid}>
          <div style={style}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="name"
                label="Your name"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input placeholder="Input your name" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Your E-mail"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="Input your email" />
              </Form.Item>
              <Form.Item
                name="subject"
                label="Your subject"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your subject!",
                  },
                ]}
              >
                <Input placeholder="Input your subject" />
              </Form.Item>
              <Form.Item
                name="message"
                label="Your message"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                <Input.TextArea rows={6} placeholder="Input your message" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </LayoutContact>
  );
}

export default FormContact;
