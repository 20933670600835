import React, { useEffect, useState } from "react";
import { client } from "../../../config/client";
import { handleColorCategory } from "../../../helper/colors";

const NavCategory = ({ handleNavCategory }) => {
  const [articles, setArticles] = useState([]);
  let arr = [];
  articles.map((article) => arr.push(article.fields.category));

  const arrNotDuplicate = arr.filter(
    (item, index) => arr.indexOf(item) === index
  );

  useEffect(() => {
    client
      .getEntries({
        content_type: "blogPost",
      })
      .then((response) => {
        setArticles(response.items);
      })
      .catch(console.error);
  }, []);

  return (
    <ul className="navigation-category">
      <li
        className="navigation-category-item"
        style={{ backgroundColor: "#673ab7", cursor: "pointer" }}
        onClick={() => handleNavCategory("All")}
      >
        All
      </li>
      {arrNotDuplicate.map((item) => (
        <li
          key={item}
          className="navigation-category-item"
          style={{
            backgroundColor: handleColorCategory(item),
            cursor: "pointer",
          }}
          onClick={() => handleNavCategory(item)}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default NavCategory;
