import React, { useState } from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import LayoutHeader from "./LayoutHeader";
import NavMenu from "./NavMenu";

function SideDrawer({ sidebarItems }) {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <LayoutHeader
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <Button
            type="default"
            onClick={showDrawer}
            style={{
              border: "none",
              backgroundColor: "#673ab7",
              color: "white",
              float: "right",
              top: "15px",
            }}
          >
            <MenuOutlined />
          </Button>
          <Drawer
            title="Menu Navigation"
            placement="right"
            onClose={onClose}
            visible={visible}
            width={300}
          >
            <NavMenu
              sidebarItems={sidebarItems}
              mode="inline"
              theme="light"
              style={{ border: "none" }}
            />
          </Drawer>
        </div>
      </LayoutHeader>
    </>
  );
}

export default SideDrawer;
