import React from "react";
import Content from "./home/Content";
import Header from "./home/header/Header";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default Dashboard;
