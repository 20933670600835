import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

function UserHelmet({ sidebarItems, article }) {
  const { pathname } = useLocation();

  return (
    <>
      {article ? (
        <HelmetProvider>
          <Helmet prioritizeSeoTags>
            ‍<title>{article?.fields?.title}</title>
            <meta
              name="description"
              content="Find all the best quality products your pet may need"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@tguhat" />
            <meta name="twitter:creator" content="@tguhat" />
            <meta name="twitter:title" content={article?.fields?.title} />
            <meta
              name="twitter:description"
              content="Best Products for your pet"
            />
            <meta name="twitter:image" content="url_to_image" />
            <meta property="og:title" content={article?.fields?.title} />
            <meta
              property="og:description"
              content="Best Products for your pet"
            />
            {/* <meta property="og:image" content="url_to_image”/>  */}
            <meta property="og:url" content="pets.abc" />
            <meta property="og:site_name" content={article?.fields?.title} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content={article?.fields?.title} />
            <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
          </Helmet>
        </HelmetProvider>
      ) : (
        <HelmetProvider>
          {sidebarItems
            .filter((item) => item.to === pathname)
            .map((item) => (
              <Helmet prioritizeSeoTags key={`for-meta-${item.name}`}>
                ‍<title>{item.name}</title>‍
                <meta
                  name="description"
                  content="Find all the best quality products your pet may need"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@tguhat" />
                <meta name="twitter:creator" content="@tguhat" />
                <meta name="twitter:title" content={item.name} />
                <meta
                  name="twitter:description"
                  content="Best Products for your pet"
                />
                <meta name="twitter:image" content="url_to_image" />
                <meta property="og:title" content={item.name} />
                <meta
                  property="og:description"
                  content="Best Products for your pet"
                />
                {/* <meta property="og:image" content="url_to_image”/>  */}
                <meta property="og:url" content="pets.abc" />
                <meta property="og:site_name" content={item.name} />
                <meta property="og:locale" content="en_US" />
                <meta
                  property="og:type"
                  content={item.name === "Blog" ? "article" : item.name}
                />
                <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
              </Helmet>
            ))}
        </HelmetProvider>
      )}
    </>
  );
}

export default UserHelmet;
