import React, { useState, useEffect } from "react";
import { Grid, Row, Col, List, Layout } from "antd";
import { NavLink } from "react-router-dom";
import CardComponent from "../../card/Card";

function Header({ articles }) {
  const gridBreakpoint = Grid.useBreakpoint();
  const [grid, setGrid] = useState();
  const articlesLimit = articles.filter((article, index) => index < 4);

  useEffect(() => {
    const getGrid = () => {
      if (gridBreakpoint.md) {
        setGrid(12);
      } else {
        setGrid(24);
      }
    };
    getGrid();
  });

  return (
    <Layout.Content>
      <Row gutter={60}>
        <Col span={grid}>
          {articles
            .filter((item, index) => index === 0)
            .map((item) => (
              <CardComponent article={item} key={item.fields.slug} />
            ))}
        </Col>
        <Col span={grid}>
          <List
            size="large"
            header={<div> ⭐ New post's update</div>}
            dataSource={articlesLimit}
            renderItem={(article) => (
              <List.Item className="header-link">
                <NavLink to={`/blog/${article.sys.id}/${article.fields.slug}`}>
                  {article.fields.title}
                </NavLink>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default Header;
