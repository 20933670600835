import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { client } from "../../config/client";
import UserHelmet from "../../helmet/UserHelmet";
import Loading from "../loading/Loading";
import Aside from "./detail/Aside";
import Content from "./detail/Content";
import Header from "./detail/Header";
import { DiscussionEmbed } from "disqus-react";

function BlogDetail() {
  const { id } = useParams();
  const [article, setArticle] = useState();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    client
      .getEntry(id)
      .then((response) => setArticle(response))
      .catch(console.error);
  }, [id]);

  if (!article) {
    return <Loading />;
  }
  if (windowSize.width <= 768) {
    return (
      <>
        <UserHelmet article={article} />
        <Header article={article} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: "2rem",
          }}
        >
          <Content width="100%" paddingRight="0" article={article} />
          <Aside
            width="100%"
            background="white"
            marginTop="30px"
            article={article}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <UserHelmet article={article} />
      <Header article={article} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "2rem 12%",
        }}
      >
        <Content width="75%" paddingRight="45px" article={article} />
        <Aside width="25%" background="white" article={article} />
      </div>
      <div
        style={{
          padding: "2rem 12%",
        }}
      >
        <DiscussionEmbed
          shortname={process.env.REACT_APP_SHORTNAME_DISQUS}
          config={{
            url: pathname,
            identifier: article.sys.id,
            title: article.fields.title,
          }}
        />
      </div>
    </>
  );
}

export default BlogDetail;
