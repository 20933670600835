import React from "react";
import Index from "../components/blog/Index";

export function Blog(props) {
  return (
    <>
      <Index />
    </>
  );
}
