import React from "react";
import { Card } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { handleColorCategory } from "../../helper/colors";
import moment from "moment";

function CardComponent(props) {
  const { article } = props;

  return (
    <Card
      cover={
        <img alt="example" src={article.fields.background.fields.file.url} />
      }
      style={{ marginBottom: 30 }}
    >
      <Card.Meta
        title={
          <Link to={`/blog/${article.sys.id}/${article.fields.slug}`}>
            {article.fields.title}
          </Link>
        }
        description={
          <span>
            <div style={{ fontSize: 13 }}>
              <span>
                {moment(article.fields.published).format("D MMMM YY")}
              </span>
              <span>
                {" "}
                <FieldTimeOutlined /> 7 menit
              </span>
            </div>
            <div style={{ margin: "10px 0 5px 0" }}>
              <span
                style={{
                  backgroundColor: handleColorCategory(article.fields.category),
                  padding: "4px 10px",
                  color: "white",
                  borderRadius: "3px",
                  fontSize: 12,
                }}
              >
                {article.fields.category}
              </span>
            </div>
          </span>
        }
      ></Card.Meta>
    </Card>
  );
}

export default CardComponent;
