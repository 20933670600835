import React from "react";
import { Layout } from "antd";
import logo from "../../images/logo.png";

function LayoutHeader({ children }) {
  return (
    <Layout.Header className="layout-header">
      <div
        className="logo"
        style={{
          float: "left",
          width: "170px",
          color: "whitesmoke",
        }}
      >
        <img width={20} src={logo} alt="brand" />
        <span style={{ marginLeft: 10 }}>teguhatma</span>
      </div>
      {children}
    </Layout.Header>
  );
}

export default LayoutHeader;
