import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography } from "antd";

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        flexDirection: "column",
      }}
    >
      <LoadingOutlined
        style={{ fontSize: 50, color: "#5d30ae", marginBottom: 15 }}
      />
      <Typography.Title level={5}>Please Wait</Typography.Title>
    </div>
  );
}

export default Loading;
