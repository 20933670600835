import React from "react";
import ListComponent from "../../list/List";
import { Typography } from "antd";

function Aside(props) {
  const { article, width, background, marginTop } = props;

  return (
    <div style={{ width: width, background: background, marginTop: marginTop }}>
      <div>
        <div
          style={{
            textAlign: "center",
            background: "#f44336",
            padding: "10px 0",
          }}
        >
          <Typography.Text
            style={{ color: "white", fontWeight: 600, fontSize: 16 }}
          >
            Other Article
          </Typography.Text>
        </div>
        <ListComponent article={article} />
      </div>
    </div>
  );
}

export default Aside;
