import React from "react";
import { List } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import CardComponent from "../card/Card";

function Articles({ articles }) {
  return (
    <Router>
      <List
        grid={{
          gutter: 40,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={articles}
        pagination={{
          onChange: (page) => {
            // console.log(page);
          },
          pageSize: 9,
          style: { textAlign: "center" },
        }}
        renderItem={(article) => (
          <List.Item>
            <CardComponent article={article} />
          </List.Item>
        )}
      />
    </Router>
  );
}

export default Articles;
