import {
  Home,
  Blog,
  Contact,
  Disclaimer,
  PrivacyPolicy,
  NotFound,
} from "../pages";
import {
  HomeOutlined,
  ReadOutlined,
  ContactsOutlined,
  AuditOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import BlogDetail from "../components/blog/BlogDetail";

export const router = [
  {
    path: "/",
    name: "Home",
    exact: true,
    component: Home,
    icon: <HomeOutlined />,
    type: "item",
  },
  {
    path: "/blog",
    name: "Blog",
    exact: true,
    component: Blog,
    icon: <ReadOutlined />,
    type: "item",
  },
  // {
  //   path: "/portfolio",
  //   name: "Portfolio",
  //   exact: true,
  //   component: Portfolio,
  //   icon: <ProjectOutlined />,
  //   type: "item",
  // },
  {
    path: "/contact",
    name: "Contact",
    exact: true,
    component: Contact,
    icon: <ContactsOutlined />,
    type: "item",
  },
  {
    path: "/disclaimer",
    name: "Disclaimer",
    exact: true,
    component: Disclaimer,
    icon: <AuditOutlined />,
    type: "item",
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    exact: true,
    component: PrivacyPolicy,
    icon: <UnlockOutlined />,
    type: "item",
  },
  {
    name: "Blog Detail",
    path: "/blog/:id/:slug",
    component: BlogDetail,
    type: "hidden",
    icon: <ContactsOutlined />,
    exact: true,
  },
  {
    name: "Blog Category",
    path: "/blog?category=:id",
    component: Blog,
    type: "hidden",
    icon: <ContactsOutlined />,
    exact: true,
  },
  {
    path: "*",
    name: "Oops! Not Found",
    component: NotFound,
    type: "404",
  },
];
