import React from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";

function NavMenu({ sidebarItems, theme, mode, style }) {
  return (
    <Menu theme={theme} mode={mode} style={style}>
      {sidebarItems
        .filter((item) => item.type !== "404")
        .filter((item) => item.type !== "hidden")
        .map((item) => (
          <Menu.Item key={`nav-${item.name}`} icon={item.icon}>
            <NavLink to={item.to}>{item.name}</NavLink>
          </Menu.Item>
        ))}
    </Menu>
  );
}

export default NavMenu;
