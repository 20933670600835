import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Typography, Divider } from "antd";
import { options } from "../../../config/formatting";
import { TagOutlined, ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";

function Content(props) {
  const { article, width, paddingRight } = props;

  return (
    <div style={{ width: width, paddingRight: paddingRight }}>
      <Typography.Title level={1}>{article.fields.title}</Typography.Title>
      <Typography.Text style={{ padding: "2px 10px", borderRadius: "3px" }}>
        by Teguhatma
      </Typography.Text>
      <TagOutlined />{" "}
      <Typography.Text
        style={{
          background: "orange",
          padding: "2px 10px",
          borderRadius: "3px",
        }}
      >
        programming
      </Typography.Text>
      <Typography.Text style={{ padding: "2px 10px", borderRadius: "3px" }}>
        <ClockCircleOutlined />{" "}
        {moment(article.fields.published).format("D MMMM YY")}
      </Typography.Text>
      <Typography.Text style={{ padding: "2px 10px", borderRadius: "3px" }}>
        3 minutes read
      </Typography.Text>
      <Divider orientation="left" style={{ marginBottom: 30 }}>
        <span
          style={{
            fontSize: 12,
            background: "#3f51b5",
            padding: "2px 10px",
            margin: "0 10px",
            color: "white",
          }}
        >
          facebook
        </span>
        <span
          style={{
            fontSize: 12,
            background: "#00bcd4",
            padding: "2px 10px",
            margin: "0 10px",
            color: "white",
          }}
        >
          twitter
        </span>
        <span
          style={{
            fontSize: 12,
            background: "#f44336",
            padding: "2px 10px",
            margin: "0 10px",
            color: "white",
          }}
        >
          email
        </span>
      </Divider>
      {documentToReactComponents(article.fields.description, options)}
    </div>
  );
}

export default Content;
