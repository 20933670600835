import React, { useEffect, useState } from "react";
import { Col, Layout, Typography } from "antd";
import { client } from "../../config/client";
import Loading from "../loading/Loading";
import marked from "marked";

function Index() {
  const [data, setData] = useState();

  useEffect(() => {
    client
      .getEntries()
      .then((responses) => {
        const result = responses.items.filter(
          (items) => items.sys.contentType.sys.id === "disclaimer"
        );
        setData(...result);
      })
      .catch(console.error);
  }, []);

  if (!data) {
    return <Loading />;
  }
  return (
    <Layout style={{ padding: "2rem 12%", background: "white" }}>
      <Col>
        <Col>
          <Typography.Title level={1}>{data.fields.name}</Typography.Title>
        </Col>
        <Col dangerouslySetInnerHTML={{ __html: marked(data.fields.desc) }} />
      </Col>
    </Layout>
  );
}

export default Index;
