import { Layout } from "antd";
import React from "react";

function LayoutContact({ children }) {
  return (
    <Layout style={{ padding: "2rem 12%", background: "white" }}>
      {children}
    </Layout>
  );
}

export default LayoutContact;
