import React from "react";
import LayoutHeader from "./LayoutHeader";
import NavMenu from "./NavMenu";

function NavbarTop({ sidebarItems }) {
  return (
    <LayoutHeader>
      <NavMenu
        sidebarItems={sidebarItems}
        mode="horizontal"
        theme="dark"
        style={{ backgroundColor: "#5d30ad" }}
      />
    </LayoutHeader>
  );
}

export default NavbarTop;
