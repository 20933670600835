import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "../components/navbar/Navigation";
import UserHelmet from "../helmet/UserHelmet";
import Footer from "../components/Footer";

const { Content } = Layout;

export function UserContainer({ routes }) {
  const [sidebarItems, setSidebarItems] = useState([]);

  useEffect(() => {
    setSidebarItems(
      Array.from(routes).map((item) => ({
        to: item.path,
        name: item.name,
        icon: item.icon,
        type: item.type,
        category: item.category,
      }))
    );
  }, [routes]);

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Router>
        <Navigation sidebarItems={sidebarItems} />

        <UserHelmet sidebarItems={sidebarItems} />

        <Content
          style={{
            minHeight: "calc(100vh - 64px)",
          }}
        >
          <div className="content px-3 py-4">
            <Switch>
              {Array.from(routes).map((route, index) => (
                <Route
                  key={`user-route-${index}`}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        </Content>
        <Footer />
      </Router>
    </Layout>
  );
}
