import React, { useEffect, useState } from "react";
import { List, Divider, Col, Typography } from "antd";
import {
  UserOutlined,
  ReadOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { client } from "../../config/client";
import CardComponent from "../card/Card";

const data = [
  {
    title: "About",
    path: "/",
    color: "rgb(198, 57, 74)",
    icon: <UserOutlined style={{ fontSize: 50, color: "#5d30ae" }} />,
    description:
      "Nama saya adalah Teguh Atma Yudha dengan lulusan informatika dari sebuah Universitas Swasta yang berada di Yogyakarta. Berpengalaman dalam bahasa pemrograman Python dan Javascript tapi saya menganggur.",
  },
  {
    title: "Blog",
    path: "/blog",
    color: "rgb(251, 177,2)",
    icon: <ReadOutlined style={{ fontSize: 50, color: "#5d30ae" }} />,
    description:
      "Artikel blog merupakan ulasan yang ditulis untuk mengingatkan penulis dan pembaca tentang teknologi, catur yang telah di lalui.",
  },
  {
    title: "Contact",
    path: "/contact",
    color: "rgb(133, 162, 196)",
    icon: <ContactsOutlined style={{ fontSize: 50, color: "#5d30ae" }} />,
    description:
      "Jika anda sedang kesulitan, butuh sesuatu. Jangan hubungi saya! karna saya juga begitu.",
  },
];

function Content() {
  const gridBreakpoint = useBreakpoint();
  const [grid, setGrid] = useState();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getGrid = () => {
      if (
        gridBreakpoint.md ||
        gridBreakpoint.lg ||
        gridBreakpoint.xl ||
        gridBreakpoint.xxl
      ) {
        setGrid(3);
      } else if (gridBreakpoint.sm) {
        setGrid(2);
      } else {
        setGrid(1);
      }
    };
    getGrid();
  });

  useEffect(() => {
    client
      .getEntries()
      .then((response) => {
        const result = response.items
          .filter((items) => items.sys.contentType.sys.id === "blogPost")
          .filter((items, index) => index < 6);

        setArticles(result);
      })
      .catch(console.error());
  }, []);

  return (
    <>
      <Col
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${grid}, 1fr)`,
          gridAutoRows: "1fr",
        }}
      >
        {data.map((item) => (
          <Col
            key={`${item.title}-menu`}
            style={{
              padding: 30,
              backgroundColor: item.color,
            }}
            // onClick={() => goNavigation(item.path)}
          >
            <Col>
              <Typography.Title level={4}>{item.title}</Typography.Title>
            </Col>
            <Col style={{ textAlign: "center", marginBottom: 20 }}>
              {item.icon}
            </Col>
            <Col>
              <Typography.Text>{item.description}</Typography.Text>
            </Col>
          </Col>
        ))}
      </Col>
      <Col style={{ padding: "2rem 12%" }}>
        <Divider orientation="left">
          <Typography.Title level={1}>Latest Post's</Typography.Title>
        </Divider>
        <List
          grid={{
            gutter: 60,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={articles}
          renderItem={(article) => (
            <List.Item style={{ margin: 0 }}>
              <CardComponent article={article} />
            </List.Item>
          )}
        />
      </Col>
    </>
  );
}

export default Content;
