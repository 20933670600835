import React, { useEffect, useState } from "react";
import { List, Space } from "antd";
import { ClockCircleOutlined, TagOutlined } from "@ant-design/icons";
import { client } from "../../config/client";
import { Link, BrowserRouter } from "react-router-dom";
import moment from "moment";

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

function ListComponent({ article }) {
  // console.log(article);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "blogPost",
      })
      .then((response) => {
        const filterArticles = response.items
          .filter((res) => res.sys.id !== article.sys.id)
          .filter((res, index) => index < 5);
        setArticles(filterArticles);
      })
      .catch(console.error);
  }, [article.sys.id]);

  return (
    <List
      itemLayout="vertical"
      dataSource={articles}
      renderItem={(article) => (
        <List.Item
          key={article.fields.title}
          actions={[
            <IconText
              icon={ClockCircleOutlined}
              text={moment(article.fields.published).format("D MMMM YY")}
              key="list-vertical-message"
            />,
            <IconText
              icon={TagOutlined}
              text={article.fields.category}
              key="list-vertical-message"
            />,
          ]}
        >
          <BrowserRouter>
            <List.Item.Meta
              title={
                <Link to={`/blog/${article.sys.id}/${article.fields.slug}`}>
                  {article.fields.title}
                </Link>
              }
              // description="hallo"
            />
          </BrowserRouter>
        </List.Item>
      )}
    />
  );
}

export default ListComponent;
