import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import Header from "./header/Header";
import NavCategory from "./navigation/NavCategory";
import Articles from "./Articles";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { client } from "../../config/client";
import { useHistory } from "react-router";

function Index() {
  const gridBreakpoint = useBreakpoint();
  const [articles, setArticles] = useState([]);
  const [categoryArt, setCategoryArt] = useState([]);

  const { push } = useHistory();

  function handleNavCategory(category) {
    push(`/blog?category=${category}`);
    if (category === "All") {
      setCategoryArt(articles);
    } else {
      const categoryArticles = articles.filter(
        (item) => item.fields.category === category
      );
      setCategoryArt(categoryArticles);
    }
  }

  useEffect(() => {
    client
      .getEntries({
        content_type: "blogPost",
      })
      .then((response) => {
        setArticles(response.items);
        setCategoryArt(response.items);
      })
      .catch(console.error);
  }, []);

  if (gridBreakpoint.md) {
    return (
      <Layout style={{ backgroundColor: "white", padding: "2rem 12%" }}>
        <Header articles={articles} />
        <NavCategory handleNavCategory={handleNavCategory} />
        <Articles articles={categoryArt} />
      </Layout>
    );
  }
  return (
    <Layout style={{ backgroundColor: "white", padding: "2rem" }}>
      <Header articles={articles} />
      <NavCategory handleNavCategory={handleNavCategory} />
      <Articles articles={categoryArt} />
    </Layout>
  );
}

export default Index;
