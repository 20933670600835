import React from "react";
import { Layout } from "antd";

function Footer() {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      Design © 2021 by teguhatma. All Right Reserved
    </Layout.Footer>
  );
}

export default Footer;
