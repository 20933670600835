import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { router } from "./router";
import { UserContainer } from "../container/UserContainer";
import { UserProvider } from "../context/UserProvider";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

function UsePageViews() {
  //   let location = useLocation();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);
}

function UserRouter() {
  UsePageViews();
  return (
    <UserProvider>
      <Router>
        <Route render={() => <UserContainer routes={router} />} />
      </Router>
    </UserProvider>
  );
}

export default UserRouter;
