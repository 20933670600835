import React from "react";
import { Col } from "antd";

function Header({ article }) {
  return (
    <Col>
      <img
        alt="hallo"
        src={`https:${article.fields.background.fields.file.url}`}
        width="100%"
        style={{ margin: "0 0 20px 0" }}
      />
    </Col>
  );
}

export default Header;
